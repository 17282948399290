.dialog-rating {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.dialog-price-item {
  max-width: 210px;
  margin: 10px 0;
}

.dialog-textfield {
  margin: 20px 0;
}
